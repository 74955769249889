import AuthenticationRoutes from 'src/app/pages/authentication/data-access/enums/authentication-routes';
import CompanyContactGroupRoutes from 'src/app/pages/company-contact-group/data-access/enums/company-contact-routes';
import CompanyContactRoutes from 'src/app/pages/company-contact/data-access/enums/company-contact-routes';
import ComponentGroupRoutes from 'src/app/pages/component-group/data-access/enums/component-group-routes';
import ComponentRoutes from 'src/app/pages/component/data-access/enums/component-routes';
import ContactPersonRoutes from 'src/app/pages/contact-person/data-access/enums/contact-person-routes';
import DocumentRoutes from 'src/app/pages/document/data-access/enums/document-routes';
import DrawingValidationRoutes from 'src/app/pages/drawing-validation/data-access/enums/drawing-validation-routes';
import EmployeeRoutes from 'src/app/pages/employee/data-access/enums/employee-routes';
import InventoryPositionRoutes from 'src/app/pages/inventory-position/data-access/enums/inventory-position-routes';
import MaterialRoutes from 'src/app/pages/material/data-access/enums/material-routes';
import ProductDocumentationTypeRoutes from 'src/app/pages/product-documentation-type/data-access/enums/product-documentation-type-routes';
import ProjectRoutes from 'src/app/pages/project/data-access/enums/project-routes';
import PurchaseRoutes from 'src/app/pages/purchase/data-access/enums/purchase-routes';
import QuoteRoutes from 'src/app/pages/quote/data-access/enums/quote-routes';
import ShipmentRoutes from 'src/app/pages/shipment/data-access/enums/shipment-routes';
import TenantSettingsRoutes from 'src/app/pages/tenant-settings/data-access/enums/tenant-settings-routes';
import UserSettingsRoutes from 'src/app/pages/user-settings/data-access/enums/user-settings-routes';
import WorkRecordRoutes from 'src/app/pages/work-record/data-access/enums/work-record-routes';
import { PermissionType } from 'src/app/shared/data-access/models/server-requests-responses.model';

export default {
	navbarItems: [
		{
			type: 'menu',
			name: 'PAGE.CRM.TITLE',
			tooltip: 'PAGE.CRM.NAVBAR.ALT',
			requiredPermissions: [PermissionType.CrmRead],
			items: [
				{
					type: 'link',
					name: 'PAGE.CONTACT_PERSON.TITLE',
					tooltip: 'PAGE.CONTACT_PERSON.NAVBAR.ALT',
					route: ContactPersonRoutes.CONTACT_PERSON_LIST,
					requiredPermissions: [PermissionType.CrmRead]
				},
				{
					type: 'link',
					name: 'PAGE.COMPANY_CONTACT.TITLE',
					tooltip: 'PAGE.COMPANY_CONTACT.NAVBAR.ALT',
					route: CompanyContactRoutes.COMPANY_CONTACT_LIST,
					requiredPermissions: [PermissionType.CrmRead]
				},
				{
					type: 'link',
					name: 'PAGE.COMPANY_CONTACT_GROUP.TITLE',
					tooltip: 'PAGE.COMPANY_CONTACT_GROUP.NAVBAR.ALT',
					route: CompanyContactGroupRoutes.COMPANY_CONTACT_GROUP_LIST,
					requiredPermissions: [PermissionType.CrmRead]
				}
			]
		},
		{
			type: 'menu',
			name: 'PAGE.QUOTE.TITLE',
			tooltip: 'PAGE.QUOTE.NAVBAR.ALT',
			requiredPermissions: [PermissionType.QuoteRead],
			items: [
				{
					type: 'link',
					name: 'PAGE.QUOTE.ALL.TITLE',
					tooltip: 'PAGE.QUOTE.NAVBAR.ALT',
					route: QuoteRoutes.QUOTE + '/' + QuoteRoutes.QUOTE_ALL_LIST,
					requiredPermissions: [PermissionType.ProjectRead]
				},
				{
					type: 'link',
					name: 'PAGE.QUOTE.ACTIVE.TITLE',
					tooltip: 'PAGE.QUOTE.ACTIVE.NAVBAR.ALT',
					route: QuoteRoutes.QUOTE + '/' + QuoteRoutes.QUOTE_ACTIVE_LIST,
					requiredPermissions: [PermissionType.ProjectRead]
				}
			]
		},
		{
			type: 'menu',
			name: 'PAGE.PROJECT.TITLE',
			tooltip: 'PAGE.PURCHASE.NAVBAR.ALT',
			requiredPermissions: [PermissionType.ProjectRead],
			items: [
				{
					type: 'link',
					name: 'PAGE.PROJECT.ALL.TITLE',
					tooltip: 'PAGE.PROJECT.NAVBAR.ALT',
					route: ProjectRoutes.PROJECT + '/' + ProjectRoutes.PROJECT_ALL_LIST,
					requiredPermissions: [PermissionType.ProjectRead]
				},
				{
					type: 'link',
					name: 'PAGE.PROJECT.ACTIVE.TITLE',
					tooltip: 'PAGE.PROJECT.ACTIVE.NAVBAR.ALT',
					route: ProjectRoutes.PROJECT + '/' + ProjectRoutes.PROJECT_ACTIVE_LIST,
					requiredPermissions: [PermissionType.ProjectRead]
				}
			]
		},
		{
			type: 'menu',
			name: 'PAGE.PURCHASE.TITLE',
			tooltip: 'PAGE.PURCHASE.NAVBAR.ALT',
			requiredPermissions: [PermissionType.PurchaseRead],
			items: [
				{
					type: 'link',
					name: 'PAGE.PURCHASE.ALL.TITLE',
					tooltip: 'PAGE.PURCHASE.ALL.NAVBAR.ALT',
					route: PurchaseRoutes.PURCHASE + '/' + PurchaseRoutes.PURCHASE_ALL_LIST,
					requiredPermissions: [PermissionType.PurchaseRead]
				},
				{
					type: 'link',
					name: 'PAGE.PURCHASE.ACTIVE.TITLE',
					tooltip: 'PAGE.PURCHASE.ACTIVE.NAVBAR.ALT',
					route: PurchaseRoutes.PURCHASE + '/' + PurchaseRoutes.PURCHASE_ACTIVE_LIST,
					requiredPermissions: [PermissionType.PurchaseRead]
				}
			]
		},
		{
			type: 'link',
			name: 'PAGE.SHIPMENT.TITLE',
			tooltip: 'PAGE.SHIPMENT.NAVBAR.ALT',
			route: ShipmentRoutes.SHIPMENT_LIST,
			requiredPermissions: [PermissionType.ShipmentRead]
		},
		{
			type: 'menu',
			name: 'PAGE.DRAWING_VALIDATION.TITLE',
			tooltip: 'PAGE.DRAWING_VALIDATION.NAVBAR.ALT',
			requiredPermissions: [PermissionType.DrawingValidationRead],
			items: [
				{
					type: 'link',
					name: 'PAGE.DRAWING_VALIDATION.ALL.TITLE',
					tooltip: 'PAGE.DRAWING_VALIDATION.ALL.NAVBAR.ALT',
					route: DrawingValidationRoutes.DRAWING_VALIDATION + '/' + DrawingValidationRoutes.DRAWING_VALIDATION_ALL_LIST,
					requiredPermissions: [PermissionType.DrawingValidationRead]
				},
				{
					type: 'link',
					name: 'PAGE.DRAWING_VALIDATION.ACTIVE.TITLE',
					tooltip: 'PAGE.DRAWING_VALIDATION.ACTIVE.NAVBAR.ALT',
					route:
						DrawingValidationRoutes.DRAWING_VALIDATION + '/' + DrawingValidationRoutes.DRAWING_VALIDATION_ACTIVE_LIST,
					requiredPermissions: [PermissionType.DrawingValidationRead]
				}
			]
		},
		{
			type: 'link',
			name: 'COMPONENT.PRODUCT.HEADLINE.MATERIALS.LABEL',
			tooltip: 'COMPONENT.PRODUCT.HEADLINE.MATERIALS.LABEL',
			route: MaterialRoutes.MATERIAL_LIST,
			requiredPermissions: [PermissionType.MaterialRead]
		},
		{
			type: 'link',
			name: 'PAGE.PRODUCT.DOCUMENTATION.TYPE.TITLE',
			tooltip: 'PAGE.PRODUCT.DOCUMENTATION.TYPE.ALT',
			route: ProductDocumentationTypeRoutes.PRODUCT_DOCUMENTAION_TYPE_LIST,
			requiredPermissions: [PermissionType.ProductDocumentationRead]
		},
		{
			type: 'link',
			name: 'GENERAL.PRODUCTION.PLAN.LABEL',
			tooltip: 'GENERAL.PRODUCTION.PLAN.ALT',
			route: EmployeeRoutes.EMPLOYEE_LIST,
			requiredPermissions: [PermissionType.ProductionPlanRead]
		},
		{
			type: 'menu',
			name: 'GENERAL.ITEM.LIST.LABEL',
			tooltip: 'PAGE.ITEM.LIST.NAVBAR.ALT',
			requiredPermissions: [PermissionType.ComponentRead, PermissionType.ComponentGroupRead],
			items: [
				{
					type: 'link',
					name: 'PAGE.COMPONENT.GROUP.TITLE',
					tooltip: 'PAGE.COMPONENT.GROUP.NAVBAR.ALT',
					route: ComponentGroupRoutes.COMPONENT_GROUP_LIST,
					requiredPermissions: [PermissionType.ComponentGroupRead]
				},
				{
					type: 'link',
					name: 'PAGE.COMPONENT.TITLE',
					tooltip: 'PAGE.COMPONENT.NAVBAR.ALT',
					route: ComponentRoutes.COMPONENT_LIST,
					requiredPermissions: [PermissionType.ComponentRead]
				},
				{
					type: 'link',
					name: 'PAGE.INVENTORY.POSITION.TITLE',
					tooltip: 'PAGE.INVENTORY.POSITION.NAVBAR.ALT',
					route: InventoryPositionRoutes.INVENTORY_POSITION_LIST,
					requiredPermissions: [PermissionType.InventoryRead]
				}
			]
		},
		{
			type: 'menu',
			name: 'PAGE.DOC.TITLE',
			tooltip: 'PAGE.DOC_ALL.NAVBAR.ALT',
			requiredPermissions: [PermissionType.DocumentRead],
			items: [
				{
					type: 'link',
					name: 'PAGE.DOC_ALL.TITLE',
					tooltip: 'PAGE.DOC_ALL.NAVBAR.ALT',
					route: DocumentRoutes.DOCUMENT + '/' + DocumentRoutes.ALL_DOCUMENTS,
					requiredPermissions: [PermissionType.DocumentRead]
				},
				{
					type: 'link',
					name: 'PAGE.DOC_MATERIAL.TITLE',
					tooltip: 'PAGE.DOC_MATERIAL.NAVBAR.ALT',
					route: DocumentRoutes.DOCUMENT + '/' + DocumentRoutes.MATERIAL_CERTIFICATE_LIST,
					requiredPermissions: [PermissionType.DocumentRead]
				},
				{
					type: 'link',
					name: 'PAGE.DOC_WELDER.TITLE',
					tooltip: 'PAGE.DOC_WELDER.NAVBAR.ALT',
					route: DocumentRoutes.DOCUMENT + '/' + DocumentRoutes.WELDER_CERTIFICATE_LIST,
					requiredPermissions: [PermissionType.DocumentRead]
				},
				{
					type: 'link',
					name: 'PAGE.DOC_WPS.TITLE',
					tooltip: 'PAGE.DOC_WPS.NAVBAR.ALT',
					route: DocumentRoutes.DOCUMENT + '/' + DocumentRoutes.WELDING_PROCEDURE_LIST,
					requiredPermissions: [PermissionType.DocumentRead]
				},
				{
					type: 'link',
					name: 'PAGE.DOC_FILLER.TITLE',
					tooltip: 'PAGE.DOC_FILLER.NAVBAR.ALT',
					route: DocumentRoutes.DOCUMENT + '/' + DocumentRoutes.FILLER_MATERIAL_CERTIFICATE_LIST,
					requiredPermissions: [PermissionType.DocumentRead]
				},
				{
					type: 'link',
					name: 'PAGE.DOC_TEMPLATE.TITLE',
					tooltip: 'PAGE.DOC_TEMPLATE.NAVBAR.ALT',
					route: DocumentRoutes.DOCUMENT + '/' + DocumentRoutes.TEMPLATE_LIST,
					requiredPermissions: [PermissionType.DocumentRead]
				},
				{
					type: 'link',
					name: 'PAGE.DOC_DATASHEET.TITLE',
					tooltip: 'PAGE.DOC_DATASHEET.NAVBAR.ALT',
					route: DocumentRoutes.DOCUMENT + '/' + DocumentRoutes.DATASHEET_LIST,
					requiredPermissions: [PermissionType.DocumentRead]
				},
				{
					type: 'link',
					name: 'PAGE.DOC_IMAGE.TITLE',
					tooltip: 'PAGE.DOC_IMAGE.NAVBAR.ALT',
					route: DocumentRoutes.DOCUMENT + '/' + DocumentRoutes.IMAGE_LIST,
					requiredPermissions: [PermissionType.DocumentRead]
				},
				{
					type: 'link',
					name: 'PAGE.DOC_ORDER_CONFIRMATION.TITLE',
					tooltip: 'PAGE.DOC_ORDER_CONFIRMATION.NAVBAR.ALT',
					route: DocumentRoutes.DOCUMENT + '/' + DocumentRoutes.ORDER_CONFIRMATION_LIST,
					requiredPermissions: [PermissionType.DocumentRead]
				},
				{
					type: 'link',
					name: 'PAGE.DOC_DRAWING.TITLE',
					tooltip: 'PAGE.DOC_DRAWING.NAVBAR.ALT',
					route: DocumentRoutes.DOCUMENT + '/' + DocumentRoutes.DRAWING_LIST,
					requiredPermissions: [PermissionType.DocumentRead]
				},
				{
					type: 'link',
					name: 'PAGE.DOC_DRAWING_VALIDATION_ATTACHMENT.TITLE',
					tooltip: 'PAGE.DOC_DRAWING_VALIDATION_ATTACHMENT.NAVBAR.ALT',
					route: DocumentRoutes.DOCUMENT + '/' + DocumentRoutes.DRAWING_ATTACHMENT_LIST,
					requiredPermissions: [PermissionType.DocumentRead]
				},
				{
					type: 'link',
					name: 'PAGE.DOC_SHIPMENT_IMAGE.TITLE',
					tooltip: 'PAGE.DOC_SHIPMENT_IMAGE.NAVBAR.ALT',
					route: DocumentRoutes.DOCUMENT + '/' + DocumentRoutes.SHIPMENT_IMAGE_LIST,
					requiredPermissions: [PermissionType.DocumentRead]
				},
				{
					type: 'link',
					name: 'PAGE.DOC_WPQR.TITLE',
					tooltip: 'PAGE.DOC_WPQR.NAVBAR.ALT',
					route: DocumentRoutes.DOCUMENT + '/' + DocumentRoutes.WPQR_LIST,
					requiredPermissions: [PermissionType.DocumentRead]
				}
			]
		}
	],
	administratorModule: {
		type: 'dropdown',
		tooltip: 'PAGE.ADMIN.NAVBAR.ALT',
		requiredPermissions: [PermissionType.EmployeeRead, PermissionType.ReferenceDataRead, PermissionType.TenantRead],
		items: [
			{
				type: 'link',
				name: 'PAGE.EMPLOYEE.TITLE',
				tooltip: 'PAGE.EMPLOYEE.NAVBAR.ALT',
				route: EmployeeRoutes.EMPLOYEE_LIST,
				requiredPermissions: [PermissionType.EmployeeRead]
			},
			{
				type: 'link',
				name: 'PAGE.WORK.RECORD.TITLE',
				tooltip: 'PAGE.WORK.RECORD.NAVBAR.ALT',
				route: WorkRecordRoutes.WORK_RECORD_LIST,
				requiredPermissions: [PermissionType.EmployeeRead]
			},
			{
				type: 'link',
				name: 'GENERAL.WAGE_RATES.LABEL',
				tooltip: 'GENERAL.WAGE_RATES.LABEL',
				route: EmployeeRoutes.EMPLOYEE_LIST,
				requiredPermissions: [PermissionType.EmployeeRead]
			},
			{
				type: 'link',
				name: 'GENERAL.FOUNDATION.DATA.GENERAL',
				tooltip: 'GENERAL.FOUNDATION.DATA.GENERAL',
				route: EmployeeRoutes.EMPLOYEE_LIST,
				requiredPermissions: [PermissionType.ReferenceDataRead]
			},
			{
				type: 'link',
				name: 'GENERAL.COMPANY.SETTINGS.LABEL',
				tooltip: 'GENERAL.COMPANY.SETTINGS.LABEL',
				route: TenantSettingsRoutes.TENANT_SETTINGS,
				requiredPermissions: [PermissionType.TenantRead]
			}
		]
	},
	userToolbar: {
		type: 'dropdown',
		name: 'USER_NAME',
		tooltip: 'PAGE.USER.NAVBAR.ALT',
		requiredPermissions: [PermissionType.BasicAccessRead],
		items: [
			{
				type: 'link',
				name: 'PAGE.USER_SETTINGS.TITLE',
				tooltip: 'PAGE.USER_SETTINGS.NAVBAR.ALT',
				route: UserSettingsRoutes.USER_SETTINGS,
				requiredPermissions: [PermissionType.BasicAccessRead]
			},
			{
				type: 'link',
				name: 'PAGE.SIGNOUT.TITLE',
				tooltip: 'PAGE.SIGNOUT.NAVBAR.ALT',
				route: AuthenticationRoutes.AUTHENTICATION + '/' + AuthenticationRoutes.SIGN_OUT,
				requiredPermissions: [PermissionType.BasicAccessRead]
			}
		]
	}
};
