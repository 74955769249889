import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, asyncScheduler, catchError, forkJoin, scheduled, takeUntil, tap } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { ApiError } from 'src/app/shared/data-access/api-error-handling/api-error';
import {
	GetAllActiveContactLogsByAppUserIdResponse,
	GetAllActiveContactLogsByTenantIdResponse,
	GetAllActiveDrawingValidationsByTenantIdResponse,
	GetAllActiveProjectsByTenantIdResponse,
	GetAllActivePurchasesByTenantIdResponse,
	GetAllActiveQuotesByTenantIdResponse,
	GetAllAwaitingCustomerQuotesByTenantIdResponse,
	GetAllDocumentWelderCertificatesAboutToBeRenewedByTenantIdResponse,
	GetAllDocumentWelderCertificatesAboutToFinallyExpireByTenantIdResponse,
	PermissionType
} from 'src/app/shared/data-access/models/server-requests-responses.model';
import { AuthenticationService } from 'src/app/shared/data-access/services/authentication/authentication.service';
import { UserInformationService } from 'src/app/shared/data-access/services/user-information/user-information.service';
import { LoadingService } from 'src/app/shared/ui/core/load-spinner/loading.service';
import { ToastManager } from 'src/app/shared/ui/services/toast/toast-manager.service';
import { ApiErrors } from 'src/app/shared/util/apiErrors/apiErrors';
import { FormatDate } from 'src/app/shared/util/formatDate/formatDate';
import CompanyContactRoutes from '../../company-contact/data-access/enums/company-contact-routes';
import ContactPersonRoutes from '../../contact-person/data-access/enums/contact-person-routes';
import DocumentRoutes from '../../document/data-access/enums/document-routes';
import { DocumentService } from '../../document/data-access/services/document.service';
import DrawingValidationRoutes from '../../drawing-validation/data-access/enums/drawing-validation-routes';
import { DrawingValidationTableMetadataService } from '../../drawing-validation/data-access/services/drawing-validation-table-metadata.service';
import { DrawingValidationService } from '../../drawing-validation/data-access/services/drawing-validation.service';
import ProjectRoutes from '../../project/data-access/enums/project-routes';
import { ProjectService } from '../../project/data-access/services/project.service';
import PurchaseRoutes from '../../purchase/data-access/enums/purchase-routes';
import { PurchaseService } from '../../purchase/data-access/services/purchase.service';
import QuoteRoutes from '../../quote/data-access/enums/quote-routes';
import { QuoteService } from '../../quote/data-access/services/quote.service';
import { ContactLogOthersTableMetadataService } from '../data-access/services/contact-logs-others-table-metadata.service';
import { ContactLogPersonalTableMetadataService } from '../data-access/services/contact-logs-personal-table-metadata.service';
import { DashboardService } from '../data-access/services/dashboard.service';
import { DocumentWelderCertificateAboutToFinallyExpireTableMetadataService } from '../data-access/services/document-welder-certificate-about-expired-table-metadata.service';
import { DocumentWelderCertificateAboutToBeRenewedTableMetadataService } from '../data-access/services/document-welder-certificate-about-to-be-renewed-table-metadata.service';
import { ProjectAllActiveTableMetadataService } from '../data-access/services/project-all-active-table-metadata.service';
import { PurchaseActiveTableMetadataService } from '../data-access/services/purchase-active-table-metadata.service';
import { QuoteAllActiveTableMetadataService } from '../data-access/services/quote-all-active-table-metadata.service';
import { QuoteAllAwaitingCustomerTableMetadataService } from '../data-access/services/quote-all-awaiting-customer-table-metadata.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.page.html',
	styleUrls: ['./dashboard.page.scss']
})
export class DashboardPage implements OnDestroy, OnInit {
	private destroy$ = new Subject<void>();
	public readonly projectAllActiveTableMetadata = this.projectAllActiveTableMetadataService.getMetadata();
	public readonly quoteAllAwaitingCustomerTableMetadata =
		this.quoteAllAwaitingCustomerTableMetadataService.getMetadata();
	public readonly quoteAllActiveTableMetadata = this.quoteAllActiveTableMetadataService.getMetadata();
	public readonly documentWelderCertificateAboutToFinallyExpireTableMetadata =
		this.documentWelderCertificateAboutToFinallyExpireTableMetadataService.getMetadata();
	public readonly documentWelderCertificateAboutToBeRenewedTableMetadata =
		this.documentWelderCertificateAboutToBeRenewedTableMetadataService.getMetadata();
	public readonly contactLogPersonalTableMetadata = this.contactLogPersonalTableMetadataService.getMetadata();
	public readonly contactLogOthersTableMetadata = this.contactLogOthersTableMetadataService.getMetadata();
	public readonly purchaseActiveTableMetadata = this.purchaseActiveTableMetadataService.getMetadata();
	public readonly drawingValidationActiveTableMetadata = this.drawingValidationTableMetadataService.getMetadata();
	public activeProjects: GetAllActiveProjectsByTenantIdResponse[] = [];
	public activeQuotes: GetAllActiveQuotesByTenantIdResponse[] = [];
	public awaitingCustomerQuotes: GetAllAwaitingCustomerQuotesByTenantIdResponse[] = [];
	public aboutToExpireDocumentWelderCertificates: GetAllDocumentWelderCertificatesAboutToFinallyExpireByTenantIdResponse[] =
		[];
	public aboutToBeRenewedDocumentWelderCertificates: GetAllDocumentWelderCertificatesAboutToBeRenewedByTenantIdResponse[] =
		[];
	public activePurchases: GetAllActivePurchasesByTenantIdResponse[] = [];
	public activedrawingValidations: GetAllActiveDrawingValidationsByTenantIdResponse[] = [];
	public personalActiveContactLogs: GetAllActiveContactLogsByAppUserIdResponse[] = [];
	public othersActiveContactLogs: GetAllActiveContactLogsByTenantIdResponse[] = [];

	public dashboardContactLogsReadPermissions = [PermissionType.DashboardContactLogsRead];
	public dashboardContactLogsAllReadPermissions = [PermissionType.DashboardContactLogsAllRead];
	public projectReadPermissions = [PermissionType.ProjectRead];
	public quoteReadPermissions = [PermissionType.QuoteRead];
	public dashboardWelderCertificateReadPermissions = [PermissionType.DashboardWelderCertificateRead];
	public purchaseReadPermissions = [PermissionType.PurchaseRead];
	public drawingValidationReadPermissions = [PermissionType.DrawingValidationRead];
	public dashboardExportPermissions = [PermissionType.DashboardExport];

	public readonly maxVisibleRows = 10;
	public readonly rowsPerPageOptions = [10, 20, 50];

	constructor(
		private projectAllActiveTableMetadataService: ProjectAllActiveTableMetadataService,
		private quoteAllAwaitingCustomerTableMetadataService: QuoteAllAwaitingCustomerTableMetadataService,
		private quoteAllActiveTableMetadataService: QuoteAllActiveTableMetadataService,
		private documentWelderCertificateAboutToFinallyExpireTableMetadataService: DocumentWelderCertificateAboutToFinallyExpireTableMetadataService,
		private documentWelderCertificateAboutToBeRenewedTableMetadataService: DocumentWelderCertificateAboutToBeRenewedTableMetadataService,
		private contactLogPersonalTableMetadataService: ContactLogPersonalTableMetadataService,
		private contactLogOthersTableMetadataService: ContactLogOthersTableMetadataService,
		private projectService: ProjectService,
		private quoteService: QuoteService,
		private documentService: DocumentService,
		private dashboardService: DashboardService,
		private loadingService: LoadingService,
		private toastManager: ToastManager,
		private translateService: TranslateService,
		private router: Router,
		public authenticationService: AuthenticationService,
		private userInformationService: UserInformationService,
		private purchaseService: PurchaseService,
		private purchaseActiveTableMetadataService: PurchaseActiveTableMetadataService,
		private titleService: Title,
		private drawingValidationService: DrawingValidationService,
		private drawingValidationTableMetadataService: DrawingValidationTableMetadataService
	) {}

	ngOnInit(): void {
		this.loadingService.startLoading();
		const tabTitle = this.translateService.instant('PAGE.DASHBOARD.TAB.LIST.TITLE');
		this.titleService.setTitle(tabTitle);

		forkJoin([
			this.getAllActiveProjectsByTenantId(),
			this.getAllActiveQuotesByTenantId(),
			this.getAllAwaitingCustomerQuotesByTenantId(),
			this.getAllDocumentWelderCertificatesAboutToFinallyExpireByTenantId(),
			this.getAllDocumentWelderCertificatesAboutToBeRenewedByTenantId(),
			this.GetAllActiveContactLogsByAppUserId(),
			this.GetAllActiveContactLogsByTenantId(),
			this.getAllPurchasesByTenantId(),
			this.getAllActiveDrawingValidationsByTenantId()
		]).subscribe(() => {
			this.contactLogDates();
			this.loadingService.stopLoading();
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public getOwnActiveQuotes(): string {
		const temp = this.activeQuotes.filter((quote) => quote.appUserId === this.userInformationService.getAppUserId());
		return temp.length.toString();
	}

	public getOwnPurchases(): string {
		const temp = this.activePurchases.filter(
			(x) => x.responsibleAppUserId === this.userInformationService.getAppUserId()
		);
		return temp.length.toString();
	}

	public getOwnDrawingValidations(): string {
		const temp = this.activedrawingValidations.filter(
			(x) =>
				x.designResponsibleAppUserId === this.userInformationService.getAppUserId() ||
				x.drawingResponsibleAppUserId === this.userInformationService.getAppUserId()
		);
		return temp.length.toString();
	}

	public getOwnAwaitingCustomerQuotes(): string {
		const temp = this.awaitingCustomerQuotes.filter(
			(quote) => quote.appUserId === this.userInformationService.getAppUserId()
		);
		return temp.length.toString();
	}

	public navigateToWelderCertificate(certificateId: string): void {
		this.router.navigate([DocumentRoutes.DOCUMENT, DocumentRoutes.WELDER_CERTIFICATE_LIST, certificateId]);
	}

	public navigateToPurchase(purchaseId: string): void {
		this.router.navigate([PurchaseRoutes.PURCHASE, PurchaseRoutes.PURCHASE_ALL_LIST, purchaseId]);
	}

	public navigateToDrawingValidation(id: string): void {
		this.router.navigate([
			DrawingValidationRoutes.DRAWING_VALIDATION,
			DrawingValidationRoutes.DRAWING_VALIDATION_ALL_LIST,
			id
		]);
	}

	public navigateToProjectDetails(projectId: string): void {
		this.router.navigate([ProjectRoutes.PROJECT, ProjectRoutes.PROJECT_ALL_LIST, projectId]);
	}

	public navigateToQuoteDetails(quoteId: string): void {
		this.router.navigate([QuoteRoutes.QUOTE, QuoteRoutes.QUOTE_ALL_LIST, quoteId]);
	}

	public navigateToContactLogPage(contactLogId: string): void {
		const contagLogPersonal = this.personalActiveContactLogs.find((log) => log.id === contactLogId);
		const contagLogOther = this.othersActiveContactLogs.find((log) => log.id === contactLogId);

		if (contagLogPersonal) {
			this.findCorrectIdAndNavigate(contagLogPersonal);
		} else if (contagLogOther) {
			this.findCorrectIdAndNavigate(contagLogOther);
		}
	}

	private findCorrectIdAndNavigate(contactLog: GetAllActiveContactLogsByTenantIdResponse): void {
		switch (contactLog.contactLogType) {
			case 'Project':
				this.router.navigate([ProjectRoutes.PROJECT, ProjectRoutes.PROJECT_ALL_LIST, contactLog.projectId]);
				break;

			case 'Quote':
				this.router.navigate([QuoteRoutes.QUOTE, QuoteRoutes.QUOTE_ALL_LIST, contactLog.quoteId]);
				break;

			case 'Contact Person':
				this.router.navigate([ContactPersonRoutes.CONTACT_PERSON_LIST, contactLog.contactPersonId]);
				break;

			case 'Company Contact':
				this.router.navigate([CompanyContactRoutes.COMPANY_CONTACT_LIST, contactLog.companyContactId]);
				break;

			default:
				break;
		}
	}

	private getAllActiveProjectsByTenantId(): Observable<GetAllActiveProjectsByTenantIdResponse[]> {
		const isAuthorized = this.authenticationService.isAuthorized(this.projectReadPermissions);
		if (!isAuthorized) {
			return scheduled([], asyncScheduler);
		}

		return this.projectService.getAllActiveProjectsByTenantId().pipe(
			takeUntil(this.destroy$),
			tap((response) => {
				this.activeProjects = response;
			}),
			catchError((error: ApiError) => {
				this.handleError(error);
				throw error;
			})
		);
	}

	private getAllActiveQuotesByTenantId(): Observable<GetAllActiveQuotesByTenantIdResponse[]> {
		const isAuthorized = this.authenticationService.isAuthorized(this.quoteReadPermissions);
		if (!isAuthorized) {
			return scheduled([], asyncScheduler);
		}

		return this.quoteService.getAllActiveQuotesByTenantId().pipe(
			takeUntil(this.destroy$),
			tap((response) => {
				this.activeQuotes = response;
			}),
			catchError((error: ApiError) => {
				this.handleError(error);
				throw error;
			})
		);
	}

	private getAllAwaitingCustomerQuotesByTenantId(): Observable<GetAllAwaitingCustomerQuotesByTenantIdResponse[]> {
		const isAuthorized = this.authenticationService.isAuthorized(this.quoteReadPermissions);
		if (!isAuthorized) {
			return scheduled([], asyncScheduler);
		}

		return this.quoteService.getAllAwaitingCustomerQuotesByTenantId().pipe(
			takeUntil(this.destroy$),
			tap((response) => {
				this.awaitingCustomerQuotes = response;
			}),
			catchError((error: ApiError) => {
				this.handleError(error);
				throw error;
			})
		);
	}

	private getAllDocumentWelderCertificatesAboutToFinallyExpireByTenantId(): Observable<
		GetAllDocumentWelderCertificatesAboutToFinallyExpireByTenantIdResponse[]
	> {
		const isAuthorized = this.authenticationService.isAuthorized(this.dashboardWelderCertificateReadPermissions);
		if (!isAuthorized) {
			return scheduled([], asyncScheduler);
		}

		return this.documentService.getAllDocumentWelderCertificatesAboutToFinallyExpireByTenantId().pipe(
			takeUntil(this.destroy$),
			tap((response) => {
				this.aboutToExpireDocumentWelderCertificates = response;
				this.formatWelderCertificatesExpire();
			}),
			catchError((error: ApiError) => {
				this.handleError(error);
				throw error;
			})
		);
	}

	private getAllDocumentWelderCertificatesAboutToBeRenewedByTenantId(): Observable<
		GetAllDocumentWelderCertificatesAboutToBeRenewedByTenantIdResponse[]
	> {
		const isAuthorized = this.authenticationService.isAuthorized(this.dashboardWelderCertificateReadPermissions);
		if (!isAuthorized) {
			return scheduled([], asyncScheduler);
		}

		return this.documentService.getAllDocumentWelderCertificatesAboutToBeRenewedByTenantId().pipe(
			takeUntil(this.destroy$),
			tap((response) => {
				this.aboutToBeRenewedDocumentWelderCertificates = response;
				this.formatWelderCertificatesRenewal();
			}),
			catchError((error: ApiError) => {
				this.handleError(error);
				throw error;
			})
		);
	}

	private getAllPurchasesByTenantId(): Observable<GetAllActivePurchasesByTenantIdResponse[]> {
		const isAuthorized = this.authenticationService.isAuthorized(this.purchaseReadPermissions);
		if (!isAuthorized) {
			return scheduled([], asyncScheduler);
		}

		return this.purchaseService.getAllActivePurchasesByTenantId().pipe(
			takeUntil(this.destroy$),
			tap((response) => {
				this.activePurchases = response;
				this.formatPurchaseDates();
			}),
			catchError((error: ApiError) => {
				this.handleError(error);
				throw error;
			})
		);
	}

	private getAllActiveDrawingValidationsByTenantId(): Observable<GetAllActiveDrawingValidationsByTenantIdResponse[]> {
		const isAuthorized = this.authenticationService.isAuthorized(this.drawingValidationReadPermissions);
		if (!isAuthorized) {
			return scheduled([], asyncScheduler);
		}

		return this.drawingValidationService.getAllActiveDrawingValidationsByTenantId().pipe(
			takeUntil(this.destroy$),
			tap((response) => {
				this.activedrawingValidations = response;
				this.formatDrawingValidationDates();
			}),
			catchError((error: ApiError) => {
				this.handleError(error);
				throw error;
			})
		);
	}

	private GetAllActiveContactLogsByAppUserId(): Observable<GetAllActiveContactLogsByAppUserIdResponse[]> {
		const isAuthorized = this.authenticationService.isAuthorized(this.dashboardContactLogsReadPermissions);
		if (!isAuthorized) {
			return scheduled([], asyncScheduler);
		}

		return this.dashboardService.GetAllActiveContactLogsByAppUserId().pipe(
			takeUntil(this.destroy$),
			tap((response) => {
				this.personalActiveContactLogs = response;
			}),
			catchError((error: ApiError) => {
				this.handleError(error);
				throw error;
			})
		);
	}

	private GetAllActiveContactLogsByTenantId(): Observable<GetAllActiveContactLogsByTenantIdResponse[]> {
		const isAuthorized = this.authenticationService.isAuthorized(this.dashboardContactLogsAllReadPermissions);
		if (!isAuthorized) {
			return scheduled([], asyncScheduler);
		}

		return this.dashboardService.GetAllActiveContactLogsByTenantId().pipe(
			takeUntil(this.destroy$),
			tap((response) => {
				this.othersActiveContactLogs = response;
			}),
			catchError((error: ApiError) => {
				this.handleError(error);
				throw error;
			})
		);
	}

	private contactLogDates(): void {
		this.personalActiveContactLogs.forEach((log) => {
			if (log.deadline) {
				log.deadline = FormatDate.formatDateInternationalToDanish(log.deadline);
			}

			if (log.createdDate) {
				log.createdDate = FormatDate.formatDateInternationalToDanish(log.createdDate);
			}
		});

		this.othersActiveContactLogs.forEach((log) => {
			if (log.deadline) {
				log.deadline = FormatDate.formatDateInternationalToDanish(log.deadline);
			}

			if (log.createdDate) {
				log.createdDate = FormatDate.formatDateInternationalToDanish(log.createdDate);
			}
		});
	}

	private formatPurchaseDates(): void {
		this.activePurchases.forEach((x) => {
			if (x.createdDate) {
				x.createdDate = FormatDate.formatDateInternationalToDanish(x.createdDate);
			}

			if (x.dateOrdered) {
				x.dateOrdered = FormatDate.formatDateInternationalToDanish(x.dateOrdered);
			}
		});
	}

	private formatWelderCertificatesExpire(): void {
		this.aboutToExpireDocumentWelderCertificates.forEach((x) => {
			if (x.finalExpiryDate) {
				x.finalExpiryDate = FormatDate.formatDateInternationalToDanish(x.finalExpiryDate);
			}
		});
	}

	private formatWelderCertificatesRenewal(): void {
		this.aboutToBeRenewedDocumentWelderCertificates.forEach((x) => {
			if (x.renewalDueDate) {
				x.renewalDueDate = FormatDate.formatDateInternationalToDanish(x.renewalDueDate);
			}
		});
	}

	private formatDrawingValidationDates(): void {
		this.activedrawingValidations.forEach((x) => {
			if (x.revisionCreatedDate) {
				x.revisionCreatedDate = FormatDate.formatDateInternationalToDanish(x.revisionCreatedDate);
			}
		});
	}

	private handleError(error: ApiError): void {
		this.loadingService.stopLoading();
		ApiErrors.displayErrorsInToasts(error, this.toastManager, this.translateService);
	}
}
