import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDa from '@angular/common/locales/da';
import localeEnGB from '@angular/common/locales/en-GB';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { providePrimeNG } from 'primeng/config';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardPageModule } from './pages/dashboard/feature/dashboard.module';
import { NumberFormatType } from './shared/data-access/models/server-requests-responses.model';
import { CustomHttpInterceptor } from './shared/data-access/services/rest/customHttp.interceptor';
import { TenantInformationService } from './shared/data-access/services/tenant-information/tenant-information.service';
import { UserInformationService } from './shared/data-access/services/user-information/user-information.service';
import { NavBarModule } from './shared/ui/core/navbar/navbar.module';
import { MyPreset } from './shared/ui/prime-ng-theme';

export function HttpLoaderFactory(httpclient: HttpClient) {
	return new TranslateHttpLoader(httpclient, environment.webApiDomain + '/appLocalization/', '');
}

// Locale factory
export function LocaleIdFactory(userInformationService: UserInformationService) {
	const localeId = userInformationService.getNumberFormat();

	switch (localeId) {
		case NumberFormatType.Danish:
			registerLocaleData(localeDa);
			return 'da';

		case NumberFormatType.English:
			registerLocaleData(localeEnGB);
			return 'en-GB';
		default:
			registerLocaleData(localeEnGB);
			return 'en-GB';
	}
}

@NgModule({
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		ToastrModule.forRoot({
			timeOut: 10000,
			extendedTimeOut: 2000,
			disableTimeOut: false,
			maxOpened: 0,
			autoDismiss: false,
			positionClass: 'toast-bottom-left',
			closeButton: false,
			easeTime: 300,
			tapToDismiss: true,
			preventDuplicates: true,
			resetTimeoutOnDuplicate: true,
			includeTitleDuplicates: true,
			newestOnTop: true
		}),
		TranslateModule.forRoot({
			defaultLanguage: 'language',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		DashboardPageModule,
		NavBarModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: CustomHttpInterceptor,
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (userInformationService: UserInformationService) => () => userInformationService.initialize(),
			deps: [UserInformationService],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (tenantInformationService: TenantInformationService) => () => tenantInformationService.initialize(),
			deps: [TenantInformationService],
			multi: true
		},
		{
			provide: LOCALE_ID,
			deps: [UserInformationService],
			useFactory: LocaleIdFactory
		},
		providePrimeNG({
			theme: {
				preset: MyPreset,
				options: {
					prefix: 'p',
					darkModeSelector: false,
					cssLayer: false
				}
			},
			ripple: true
		}),
		provideHttpClient(withInterceptorsFromDi())
	]
})
export class AppModule {}
